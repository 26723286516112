import React, { useCallback, useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import './YouGotReferred.scss'
import Img from 'gatsby-image'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { formatForBigQuery, isMobile } from '../../formatForBigQuery'
import FullscreenVideo from '../FullscreenVideo/FullscreenVideo'
import { GENERIC_APK } from '../download-links'
import { getApkLink } from '../../lib/get-apk-from-utm-param'

interface Props {
  apkLink?: string
  referrerName?: string
  referrerId?: string
  referrerImageUrl?: string
  referralReward?: number
  referralContact?: string
  buyIn?: number
  bigBlind?: number
  pointRate?: number
  gameName?: string
  gameImageUrl?: string
}

const YouGotReferred: React.FC<Props> = ({
  referrerName,
  referrerId,
  referrerImageUrl,
  referralReward,
  referralContact,
  buyIn,
  bigBlind,
  pointRate,
  gameName,
  gameImageUrl,
  apkLink,
}) => {
  const imageKitBaseUrl = 'https://img1.getmegacdn.com'
  const src =
    'https://player.vimeo.com/video/475764833?title=0&byline=0&portrait=0&autoplay=1&background=1'

  const [showVideo, setShowVideo] = useState(false)
  const [apk, setApk] = useState(GENERIC_APK)

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      star: file(relativePath: { eq: "star-48.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      icon1: file(relativePath: { eq: "referral/icon1.png" }) {
        childImageSharp {
          fixed(width: 36) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon2: file(relativePath: { eq: "referral/icon2.png" }) {
        childImageSharp {
          fixed(width: 36) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      icon3: file(relativePath: { eq: "referral/icon3.png" }) {
        childImageSharp {
          fixed(width: 36) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      trophy: file(relativePath: { eq: "referral/trophy.png" }) {
        childImageSharp {
          fixed(width: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const updateApk = useCallback(async () => {
    setApk(await getApkLink(apkLink))
  }, [apkLink])
  useEffect(() => {
    updateApk()
  }, [updateApk])

  const onDownload = () => {
    setShowVideo(true)
  }

  const [scrolled, setScrolled] = useState(false)
  const scrollTarget = document.getElementsByClassName('auto-download')[0]
  const downloadStartElement =
    document.getElementsByClassName('features-wrap')[0]

  useEffect(() => {
    if (isMobile() && !scrolled) {
      window.addEventListener('scroll', () => {
        if (
          scrollTarget &&
          (downloadStartElement as HTMLElement).offsetTop - window.scrollY <= 0
        ) {
          ;(scrollTarget as HTMLElement).click()
          setScrolled(true)
        }
      })
    }
  }, [downloadStartElement, scrollTarget, scrolled])

  const topContent = (
    <>
      <div className="header">
        <h2>
          Your friend <strong>{referrerName}</strong> has gifted you ₹
          <strong>{referralReward}</strong>. <br />
          Play games with them to find out who&apos;s the boss!
        </h2>
      </div>
      <div className="images">
        <div className="trophy-image">
          <Img fixed={data.trophy.childImageSharp.fixed} alt="Mega" />
        </div>
        <img
          src={`${imageKitBaseUrl}/${gameImageUrl}`}
          className="image game"
          alt="game"
          width="100"
          height="100"
        />
        <img
          src={`${imageKitBaseUrl}/${referrerImageUrl}`}
          className="image referrer"
          alt="referrer"
          width="100"
          height="100"
        />
      </div>
      <p className="caption-text">Sign up, play, &amp; win money!</p>
    </>
  )

  const showVideoCode = showVideo && (
    <FullscreenVideo
      src={src}
      close={() => {
        setShowVideo(false)
      }}
    />
  )

  return (
    <>
      <div id="YouGotReferred" className="mobile-view d-block d-md-none">
        <Container>
          <div className="top-logo">
            <Link to="/">
              <Img fixed={data.logo.childImageSharp.fixed} alt="Mega" />
            </Link>
          </div>
          {topContent}
          <div className="referral-bottom">
            <a href={apk} className="auto-download" hidden>
              Download App
            </a>
            <div className="features-wrap">
              <div className="feature-item">
                <Img fixed={data.icon1.childImageSharp.fixed} alt="image" />
                <span>Instant Withdrawal</span>
              </div>
              <div className="feature-item">
                <Img fixed={data.icon2.childImageSharp.fixed} alt="image" />
                <span>Legally Approved</span>
              </div>
              <div className="feature-item">
                <Img fixed={data.icon3.childImageSharp.fixed} alt="image" />
                <span>Fair Gameplay Certified</span>
              </div>
            </div>
            <div className="download-btn">
              <a href={apk} onClick={onDownload}>
                Download App
              </a>
            </div>
            <div className="rating">
              <div>
                <Img
                  loading="eager"
                  fluid={data.star.childImageSharp.fluid}
                  alt="star"
                  className="rating-star"
                />
                <span>4.7 Rating</span>
              </div>
              <div className="rating-separator">•</div>
              <div>5M+ Downloads</div>
            </div>
          </div>
        </Container>
        {showVideoCode}
      </div>
      <div id="YouGotReferred" className="d-none d-md-block">
        <Container>{topContent}</Container>
        {showVideoCode}
      </div>
    </>
  )
}

export default YouGotReferred
