import {
  GENERIC_APK,
  GENERIC_REFER_APK,
  MEGA_CAMPAIGNS_TEMPLATE_ONELINK,
} from '../components/download-links'

const mediumKey = 'utm_medium'

const megaCampaignOnelinkKey = 'mc_onelinkId'

const getQueryVariable = (variable: any) => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

export const getMegaCampaignOnelinkId = () =>
  getQueryVariable(megaCampaignOnelinkKey)

const getCampaignApkLink = () => {
  const campaignOnelinkID = getMegaCampaignOnelinkId()

  if (campaignOnelinkID) {
    return `${MEGA_CAMPAIGNS_TEMPLATE_ONELINK}${campaignOnelinkID}`
  }
  return false
}

const getWhatsappApkLink = () => {
  const medium = getQueryVariable(mediumKey)
  const prevMedium = sessionStorage.source

  if (medium === false) {
    if (prevMedium === 'whatsapp') {
      return GENERIC_REFER_APK
    }
  }

  if (medium === 'whatsapp') {
    sessionStorage.setItem('source', medium)
    return GENERIC_REFER_APK
  }

  if (medium) sessionStorage.setItem('source', medium)

  return false
}

const getRandomApkLink = async (): Promise<string> => {
  const endpoint = 'https://app-distributor.getmega.workers.dev/app-percentages'
  const distribution = await fetch(endpoint)
    .then(response => response.json())
    .then(data => data)

  const random = Math.floor(Math.random() * 100) + 1
  let subtotal = 0
  let apklink = ''
  distribution.forEach((d: any) => {
    const p = parseInt(d.percentage, 10)
    const newsubtotal = subtotal + p
    if (random > subtotal && random <= newsubtotal) {
      apklink = d.app_id
    }
    subtotal = newsubtotal
  })

  return apklink
}

export const getApkLink = async (
  defaultLink?: string | undefined
): Promise<string> => {
  try {
    const campaignOnelink = getCampaignApkLink()
    if (campaignOnelink) {
      return campaignOnelink
    }

    if (defaultLink) return defaultLink

    const whatsappOnelink = getWhatsappApkLink()
    if (whatsappOnelink) {
      return whatsappOnelink
    }

    const appLink = await getRandomApkLink()

    return appLink || GENERIC_APK
  } catch (err) {
    console.error(err)
    return GENERIC_APK
  }
}
