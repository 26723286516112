import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Container from 'react-bootstrap/esm/Container'
import { REFER_EARN } from '../../internal-links'
import LinkButton from '../../LinkButton/LinkButton'
import Title from '../../Title/Title'
import './ReferEarnCTA.scss'

const ReferEarnCTA: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      referCta: file(relativePath: { eq: "refer-cta.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [gameCount, setGameCount] = useState(85379)
  const [referCount, setReferCount] = useState(7983200)

  function gameCounter() {
    setGameCount(prevGameCount => prevGameCount + 1)
  }

  function referCounter() {
    setReferCount(prevReferCount => prevReferCount + 50)
  }

  useEffect(() => {
    const gameFunc = setInterval(() => {
      gameCounter()
    }, 7000)
    const referFunc = setInterval(() => {
      referCounter()
    }, 4000)
    return () => {
      clearInterval(gameFunc)
      clearInterval(referFunc)
    }
  }, [])

  return (
    <div className="refer-earn-cta">
      <Container>
        <div className="rec-container">
          <div className="rec-image">
            <GatsbyImage
              image={data.referCta.childImageSharp.gatsbyImageData}
              alt="refer and earn"
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
          <div className="rec-content">
            <Title color="white">
              <div className="secs">Refer and Earn</div>
            </Title>
            <p>
              Refer your friends to play games on the Mega app and get a chance
              to win up to ₹25,000. Refer more to earn more.
            </p>
            <div className="refer-button">
              <LinkButton to={REFER_EARN} color="white">
                Refer Now
              </LinkButton>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ReferEarnCTA
