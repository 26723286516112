import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout'
import YouGotReferredContent from '../components/YouGotReferred/YouGotReferred'
import { DOWNLOAD } from '../components/download-links'
import SEO from '../components/seo'
import InstructionCarousel from '../components/Download/InstructionCarousel'
import ReferEarnCTA from '../components/Index/ReferEarnCTA/ReferEarnCTA'
import HomeFAQs from '../components/Index/HomeFAQs/HomeFAQs'
import './you-got-referred.scss'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'

const YouGotReferred: React.FC = () => {
  const [apkLink, setApkLink] = useState<string>(DOWNLOAD)

  const [referrerId, setReferrerId] = useState<string>()
  const [referrerName, setReferrerName] = useState<string>()
  const [referrerImageUrl, setReferrerImageUrl] = useState<string>()

  const [referralReward, setReferralReward] = useState<number>()
  const [referralContact, setReferralContact] = useState<string>()

  const [buyIn, setBuyIn] = useState<number>()
  const [bigBlind, setBigBlind] = useState<number>()
  const [pointRate, setPointRate] = useState<number>()

  const [gameName, setGameName] = useState<string>()
  const [gameImageUrl, setGameImageUrl] = useState<string>()

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const { search } = window.location
    let params = new URLSearchParams(search)
    const downloadUrl = params.get('downloadUrl')
    if (!downloadUrl) {
      navigate('/')
      return
    }

    params = new URLSearchParams(downloadUrl)

    setApkLink(downloadUrl)

    setReferrerId(params.get('referrer_id') || '')
    setReferrerName((params.get('display_name') || '')?.split(' ')[0])
    setReferrerImageUrl(params.get('image_url') || '')
    setReferralReward(Number(params.get('referral_amount')))
    setReferralContact(params.get('receiver_contact') || '')

    setBuyIn(Number(params.get('buy_in')))
    setBigBlind(Number(params.get('big_blind')))
    setPointRate(Number(params.get('point_rate')))

    setGameName(params.get('ugc_game_name') || '')
    setGameImageUrl(params.get('ugc_game_icon_url') || '')
  }, [])

  useEffect(() => {
    if (
      !referrerId &&
      !referrerName &&
      !referrerImageUrl &&
      !referralReward &&
      !referralContact &&
      !buyIn &&
      !bigBlind &&
      !pointRate &&
      !gameName &&
      !gameImageUrl
    ) {
      return
    }

    if (
      !referrerId ||
      !referrerName ||
      !referrerImageUrl ||
      !referralReward ||
      !buyIn ||
      (!bigBlind && !pointRate) ||
      !gameName ||
      !gameImageUrl
    ) {
      navigate('/')
    } else {
      setLoading(false)
    }
  }, [
    referrerId,
    referrerName,
    referrerImageUrl,
    referralReward,
    referralContact,
    buyIn,
    bigBlind,
    pointRate,
    gameName,
    gameImageUrl,
  ])

  return (
    <>
      {!loading && (
        <Layout
          hideMobileHeader
          hideMobileFooter
          apkLink={apkLink}
          hideDownloadPopUp
          marqueeText="Refer and Earn | Earn Rs 50 on Signup | Earn Rs 200 on first deposit"
        >
          <h1 className="refer-heading">
            Play real money social games with video chat
          </h1>
          <YouGotReferredContent
            referrerName={referrerName}
            referrerImageUrl={referrerImageUrl}
            referralReward={referralReward}
            referralContact={referralContact}
            buyIn={buyIn}
            bigBlind={bigBlind}
            pointRate={pointRate}
            gameName={gameName}
            gameImageUrl={gameImageUrl}
            referrerId={referrerId}
            apkLink={apkLink}
          />
          <InstructionCarousel />
          <div className="refer-page-refer-cta">
            <ReferEarnCTA />
          </div>
          <div className="refer-faqs">
            <HomeFAQs allFaqText="view all" />
          </div>
        </Layout>
      )}
      <SEO
        title="Mega: Play Poker & Rummy To Earn Money!"
        description="Play Poker & Rummy to win money! Download Mega to play exciting games. Instant withdrawals. Verified profiles. Play now!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
      />
    </>
  )
}

export default YouGotReferred
